<template>
  <div>
    <el-input
      v-model="SMSCode"
      maxlength="6"
      clearable
      :disabled="SMSCodeDisabled"
      placeholder="请输入短信验证码"
      @input="input"
    >
      <template slot="append">
        <div v-loading="loading" class="p-l-10 p-r-10">
          <el-button @click="sendSMSCode" type="text" :disabled="sendDisabled">
            {{ sendVal }}
          </el-button>
        </div>
      </template>
    </el-input>
  </div>
</template>

<script>
import { getCaptchasSms } from "@/api/captchas";
import { phoneValidate } from "@/utils/validate";

export default {
  name: "SMSCode",
  props: {
    smsCaptcha: {
      type: String,
      default: ""
    },
    phone: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      desc: "客户端-短信验证码组件",
      nums: 0,
      SMSCode: "",
      loading: false,
      isSendBtn: false,
      isSendSMSCode: false,
      timer: null
    };
  },
  watch: {
    smsCaptcha: {
      immediate: true,
      handler: function() {
        this.SMSCode = this.smsCaptcha;
      }
    }
  },
  computed: {
    sendVal() {
      if (this.nums) {
        return this.nums + "s重新发送";
      } else {
        return "获取验证码";
      }
    },
    SMSCodeDisabled() {
      // return !this.isSendSMSCode || !phoneValidate(this.phone);
      return !phoneValidate(this.phone);
    },
    sendDisabled() {
      return this.nums || !phoneValidate(this.phone);
    }
  },
  methods: {
    input() {
      this.$emit("input", this.SMSCode);
    },
    sendSMSCode() {
      if (!this.type) {
        this.$message.error("请传入状态！");
        return;
      }
      this.loading = true;
      const data = {
        phone: this.phone, // 手机号
        type: this.type // 场景 bindErp: 绑定ERP login: 验证码登录 resetPassword: 重置密码 register: 注册 checkPhone: 检查手机号 changePhone: 绑定手机号
      };
      getCaptchasSms(data)
        .then(() => {
          this.loading = false;
          this.isSendSMSCode = true;
          this.countDown(120);
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "发送失败！");
        });
    },
    countDown(num) {
      let times = num;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.nums = times;
      this.timer = setInterval(() => {
        if (times > 0) {
          times--;
          this.nums = times;
        } else {
          this.nums = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped></style>
