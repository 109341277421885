<template>
  <el-dialog
    title="绑定手机号"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="440px"
    @close="close"
  >
    <div class="content-box">
      <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
        <el-form-item label="用户名：" prop="account">
          <el-input v-model="formData.account" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="formData.phone" maxlength="11" placeholder="请输入需要绑定的手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="smsCaptcha">
          <SMSCode
            v-model="formData.smsCaptcha"
            :smsCaptcha="formData.smsCaptcha"
            :phone="formData.phone"
            type="changePhone"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button class="width-full" type="primary" @click="confirm">确认绑定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import SMSCode from "@/components/SMSCode";
import { phoneValidate, phoneValidateTips } from "@/utils/validate";
import { updatePhone } from "@/api/base";

export default {
  name: "BindPhoneBox",
  components: {
    SMSCode
  },
  data() {
    const validator = (rule, value, callback) => {
      if (rule.field === "phone") {
        if (!value) {
          callback(new Error("请输入需要绑定的手机号！"));
        } else {
          if (phoneValidate(value)) {
            callback();
          } else {
            callback(new Error(phoneValidateTips));
          }
        }
      }
      if (rule.field === "smsCaptcha") {
        if (!value) {
          callback(new Error("请输入短信验证码！"));
        } else {
          if (value.length === 6) {
            callback();
          } else {
            callback(new Error("验证码位数不正确！"));
          }
        }
      }
    };
    return {
      desc: "绑定手机号",
      visible: false,
      formData: {
        account: "",
        phone: "",
        smsCaptcha: ""
      },
      rules: {
        phone: [{ validator: validator, trigger: "blur", required: true }],
        smsCaptcha: [{ validator: validator, trigger: "blur", required: true }]
      }
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  methods: {
    open() {
      this.formData.account = this.userInfo.account;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            phone: this.formData.phone,
            smsCaptcha: this.formData.smsCaptcha
          };
          updatePhone(data)
            .then(() => {
              this.$message.success("绑定成功");
              // 获取用户信息
              this.$store.dispatch("user/getUserInfo");
              this.close();
            })
            .catch(err => {
              this.$message.error(err.message || "绑定失败！");
            });
        }
      });
    }
  }
};
</script>
