<template>
  <el-dialog
    title="关注公众号"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="440px"
    @close="close"
  >
    <div>
      <div class="m-t-20 flex flex-center">
        <img :src="offiaccountUrl" alt="" class="qrcode-img" />
      </div>
      <div class="m-t-20 text-center">微信扫码关注公众号</div>
    </div>
  </el-dialog>
</template>

<script>
import { getAccountsTicket } from "@/api/accounts";

export default {
  name: "BindOffiaccountBox",
  data() {
    return {
      desc: "公众号绑定",
      offiaccountUrl: "", // 公众号图片链接
      visible: false
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.getAccountsTicketFn();
    },
    // 当前用户公众号二维码
    getAccountsTicketFn() {
      getAccountsTicket()
        .then(res => {
          this.offiaccountUrl = window.tool.imageCrossReplace(res);
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    close() {
      // 刷新用户信息
      this.$store.dispatch("user/getUserInfo");
      this.visible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.qrcode-img {
  width: 160px;
  height: 160px;
  padding: 5px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>
