<template>
  <el-dialog
    v-if="visible"
    title="修改手机号"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="440px"
    @close="close"
  >
    <el-steps :active="active" finish-status="success">
      <el-step title="账号验证"></el-step>
      <el-step title="新手机号验证"></el-step>
      <el-step title="变更成功"></el-step>
    </el-steps>
    <div class="m-t-20">
      <el-form v-show="active === 0" ref="formOne" :model="stepOne" :rules="stepOneRules" label-width="80px">
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="stepOne.phone" maxlength="11" placeholder="请输入需要验证的手机号" disabled></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="smsCaptcha">
          <SMSCode
            v-model="stepOne.smsCaptcha"
            :smsCaptcha="stepOne.smsCaptcha"
            :phone="stepOne.phone"
            type="checkPhone"
          />
        </el-form-item>
      </el-form>
      <el-form v-show="active === 1" ref="formTwo" :model="stepTwo" :rules="stepTwoRules" label-width="80px">
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="stepTwo.phone" maxlength="11" placeholder="请输入需要绑定的手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="smsCaptcha">
          <SMSCode
            v-model="stepTwo.smsCaptcha"
            :smsCaptcha="stepTwo.smsCaptcha"
            :phone="stepTwo.phone"
            type="changePhone"
          />
        </el-form-item>
      </el-form>
      <div v-if="active === 2">
        <div class="flex-column flex-center">
          <i class="el-icon-success font-size-30"></i>
          <div class="font-size-18 m-t-10">手机号码变更成功</div>
          <div class="text-666 m-t-10">您的手机号码已经变更成功, 下次请用新手机号登录。</div>
        </div>
      </div>
    </div>
    <div class="m-t-20">
      <el-button v-loading="loading" class="width-full" type="primary" @click="nextStep">
        {{ active < 2 ? "下一步" : "完成" }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
import SMSCode from "@/components/SMSCode";
import { phoneValidate, phoneValidateTips } from "@/utils/validate";
import { checkPhone, updatePhone } from "@/api/base";

export default {
  name: "UpdatePhoneDialog",
  components: {
    SMSCode
  },
  props: {
    telPhone: {
      type: Object
    }
  },
  data() {
    const validator = (rule, value, callback) => {
      if (rule.field === "phone") {
        if (!value) {
          callback(new Error("请输入需要绑定的手机号！"));
        } else {
          if (phoneValidate(value)) {
            callback();
          } else {
            callback(new Error(phoneValidateTips));
          }
        }
      }
      if (rule.field === "smsCaptcha") {
        if (!value) {
          callback(new Error("请输入短信验证码！"));
        } else {
          if (value.length === 6) {
            callback();
          } else {
            callback(new Error("验证码位数不正确！"));
          }
        }
      }
    };
    return {
      desc: "修改手机号",
      visible: false,
      loading: false,
      active: 0,
      stepOne: {
        phone: "",
        smsCaptcha: ""
      },
      stepOneRules: {
        phone: [{ validator: validator, trigger: "blur", required: true }],
        smsCaptcha: [{ validator: validator, trigger: "blur", required: true }]
      },
      stepTwo: {
        phone: "",
        smsCaptcha: ""
      },
      stepTwoRules: {
        phone: [{ validator: validator, trigger: "blur", required: true }],
        smsCaptcha: [{ validator: validator, trigger: "blur", required: true }]
      }
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  methods: {
    open() {
      this.stepOne.phone = this.userInfo.phone;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.stepOne = {};
      this.stepTwo = {};
      this.active = 0;
    },
    nextStep() {
      // 账号验证
      if (this.active === 0) {
        this.$refs.formOne.validate(valid => {
          if (valid) {
            const data = {
              phone: this.stepOne.phone,
              smsCaptcha: this.stepOne.smsCaptcha
            };
            this.loading = true;
            checkPhone(data)
              .then(() => {
                this.loading = false;
                this.active += 1;
              })
              .catch(err => {
                this.loading = false;
                this.$message.error(err.message || "账号验证失败！");
              });
          }
        });
        return;
      }
      // 新手机号验证
      if (this.active === 1) {
        this.$refs.formTwo.validate(valid => {
          if (valid) {
            const data = {
              phone: this.stepTwo.phone,
              smsCaptcha: this.stepTwo.smsCaptcha
            };
            this.loading = true;
            updatePhone(data)
              .then(() => {
                this.loading = false;
                this.active += 1;
                // 获取用户信息
                this.$store.dispatch("user/getUserInfo");
              })
              .catch(err => {
                this.loading = false;
                this.$message.error(err.message || "验证失败！");
              });
          }
        });
      }
      // 完成
      if (this.active === 2) {
        this.close();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.el-icon-success {
  color: #02eb6c;
  font-size: 80px;
}
</style>
