<template>
  <el-dialog
    v-if="visible"
    title="密码修改"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="原密码修改" name="one">
          <el-form ref="formOne" :model="formOneData" :rules="formOneRules" label-width="100px">
            <el-form-item label="用户名：" prop="account">
              <el-input v-model="formOneData.account" disabled></el-input>
            </el-form-item>
            <el-form-item label="原密码：" prop="oldPassword">
              <PassWord
                ref="passWord"
                v-model="formOneData.oldPassword"
                :password="formOneData.oldPassword"
                :is-message="false"
              />
            </el-form-item>
            <el-form-item label="新密码：" prop="newPassword">
              <PassWord
                ref="newPassword"
                v-model="formOneData.newPassword"
                :password="formOneData.newPassword"
                :is-message="false"
              />
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
              <PassWord
                v-model="formOneData.confirmPassword"
                :password="formOneData.confirmPassword"
                :is-message="false"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="手机验证修改" name="two">
          <el-form ref="formTwo" :model="formTwoData" :rules="formTwoRules" label-width="100px">
            <el-form-item label="用户名：" prop="account">
              <el-input v-model="formTwoData.account" disabled></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
              <el-input v-model="formTwoData.phone" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="smsCaptcha">
              <SMSCode
                v-model="formTwoData.smsCaptcha"
                :smsCaptcha="formTwoData.smsCaptcha"
                :phone="formTwoData.phone"
                type="resetPassword"
              />
            </el-form-item>
            <el-form-item label="新密码：" prop="newPassword">
              <PassWord
                ref="newPassword"
                v-model="formTwoData.newPassword"
                :password="formTwoData.newPassword"
                :is-message="false"
              />
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
              <PassWord
                v-model="formTwoData.confirmPassword"
                :password="formTwoData.confirmPassword"
                :is-message="false"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div slot="footer" class="text-right">
      <el-button plain size="small" @click="close">取消</el-button>
      <el-button v-loading="loading" type="primary" size="small" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import PassWord from "@/components/PassWord";
import SMSCode from "@/components/SMSCode";
import { passwordValidate, passwordValidateTips, phoneValidate, phoneValidateTips } from "@/utils/validate";
import { updatePassword, updateResetPassword } from "@/api/base";

export default {
  name: "ModifyPwdBox",
  components: {
    PassWord,
    SMSCode
  },
  data() {
    const validatorOne = (rule, value, callback) => {
      if (!passwordValidate(value)) {
        callback(new Error(passwordValidateTips));
      } else {
        if (rule.field === "confirmPassword") {
          if (value !== this.formOneData.newPassword) {
            callback(new Error("两次密码不匹配！"));
          } else {
            callback();
          }
        } else if (rule.field === "newPassword") {
          if (value === this.formOneData.oldPassword) {
            callback(new Error("原密码与新密码相同！"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    const validatorTwo = (rule, value, callback) => {
      if (rule.field === "phone") {
        if (!value) {
          callback(new Error("请输入手机号！"));
        } else {
          if (phoneValidate(value)) {
            callback();
          } else {
            callback(new Error(phoneValidateTips));
          }
        }
      }
      if (rule.field === "smsCaptcha") {
        if (!value) {
          callback(new Error("请输入短信验证码！"));
        } else {
          if (value.length === 6) {
            callback();
          } else {
            callback(new Error("验证码位数不正确！"));
          }
        }
      }
      if (rule.field === "newPassword") {
        if (!passwordValidate(value)) {
          callback(new Error(passwordValidateTips));
        } else {
          callback();
        }
      }
      if (rule.field === "confirmPassword") {
        if (!passwordValidate(value)) {
          callback(new Error(passwordValidateTips));
        } else {
          if (value !== this.formTwoData.newPassword) {
            callback(new Error("两次密码不匹配！"));
          } else {
            callback();
          }
        }
      }
    };
    return {
      desc: "密码修改",
      visible: false,
      loading: false,
      activeName: "one",
      formOneData: {
        account: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      formOneRules: {
        oldPassword: [{ validator: validatorOne, trigger: "blur", required: true }],
        newPassword: [{ validator: validatorOne, trigger: "blur", required: true }],
        confirmPassword: [{ validator: validatorOne, trigger: "blur", required: true }]
      },
      formTwoData: {
        account: "",
        phone: "",
        smsCaptcha: "",
        newPassword: "",
        confirmPassword: ""
      },
      formTwoRules: {
        phone: [{ validator: validatorTwo, trigger: "blur", required: true }],
        smsCaptcha: [{ validator: validatorTwo, trigger: "blur", required: true }],
        newPassword: [{ validator: validatorTwo, trigger: "blur", required: true }],
        confirmPassword: [{ validator: validatorTwo, trigger: "blur", required: true }]
      }
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    })
  },
  methods: {
    open() {
      this.formOneData.account = this.userInfo.account;
      this.formTwoData.account = this.userInfo.account;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.formOneData = {};
      this.formTwoData = {};
      this.activeName = "one";
    },
    confirm() {
      if (this.activeName === "one") {
        this.$refs.formOne.validate(valid => {
          if (valid) {
            this.loading = true;
            updatePassword(this.formOneData)
              .then(() => {
                this.loading = false;
                this.$message.success("密码成功修改");
                this.close();
              })
              .catch(err => {
                this.loading = false;
                this.$message.error(err.message || "密码成功失败！");
              });
          }
        });
      } else {
        this.$refs.formTwo.validate(valid => {
          if (valid) {
            this.loading = true;
            updateResetPassword(this.formTwoData)
              .then(() => {
                this.loading = false;
                this.$message.success("密码成功修改");
                this.close();
              })
              .catch(err => {
                this.loading = true;
                this.$message.error(err.message || "密码成功失败！");
              });
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs {
  .el-tabs__nav {
    width: 100%;
    display: flex;
    .el-tabs__item {
      flex: 1;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
