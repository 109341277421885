<template>
  <div class="client-page p-tb-16 p-lr-20" style="overflow:auto">
    <div class="font-size-16 font-weight-bold">个人信息</div>
    <div class="m-tb-20 m-l-20">
      <div class="flex m-t-10">
        <div class="w-100  m-t-10">头像：</div>
        <div class="w-80 h-80 avatar-box">
          <el-image class="w-80 h-80" :src="userInfo.avatar || defaultAvatar" fit="cover"></el-image>
          <div v-if="false" class="img-upload" @click="chooseImg">上传头像</div>
          <input type="file" name="" ref="imgUploadRef" style="display:none;" @change="onFileChange" />
        </div>
      </div>
      <div class="flex flex-row-center m-t-10 line-height-32">
        <span class="w-100">姓名：</span>
        <span class="font-size-16 font-weight-bold" v-if="!isEditUsername">
          {{ userInfo.username }}
          <i v-if="false" class="el-icon-edit cursor-pointer m-l-5" @click="isEditUsername = true"> </i>
        </span>
        <div class="flex" v-if="isEditUsername">
          <el-input v-model="userInfo.username" size="small" clearable class="w-200 m-r-10" />
          <el-button type="primary" size="small" @click="saveUsername">保存</el-button>
          <el-button plain size="small" @click="isEditUsername = false">取消</el-button>
        </div>
      </div>
      <div class="flex m-t-10 line-height-32">
        <span class="w-100">登录用户名：</span>
        <span>{{ userInfo.account }}</span>
      </div>
      <div v-if="false" class="flex m-t-10 line-height-32">
        <span class="w-100">所在地区：</span>
        <span v-if="!isEditUserarea">
          {{ userInfo.areaName || "- -" }}
          <i v-if="userType === 'personal'" class="el-icon-edit cursor-pointer m-l-5" @click="isEditUserarea = true">
          </i>
        </span>
        <div class="flex" v-if="isEditUserarea">
          <SelectArea
            class="m-r-10"
            style="width:200px"
            size="mini"
            :areaData="userInfo.areaIds || []"
            @change="areaChange"
          />
          <el-button type="primary" size="small" @click="saveUserarea">保存</el-button>
          <el-button plain size="small" @click="isEditUserarea = false">取消</el-button>
        </div>

        <!-- <span v-else>{{ userInfo.areaName || "- -" }}</span> -->
      </div>
      <div class="flex m-t-10 line-height-32">
        <span class="w-100">所属公司：</span>
        <span>{{ userInfo.companyName }}</span>
      </div>
    </div>
    <div class="font-size-16 font-weight-bold">账号与安全</div>
    <div class="m-tb-20 m-l-20" style="width: 80%">
      <div class="flex flex-row-center flex-between border-bottom-1 p-a-10">
        <div class="flex flex-row-center flex1">
          <i class="el-icon-success text-success font-size-26 m-r-20"></i>
          <span>登录密码</span>
        </div>
        <el-button type="text" @click="editPwd">修改密码</el-button>
      </div>
      <div class="flex flex-row-center flex-between border-bottom-1 p-a-10">
        <div class="flex flex-row-center flex1">
          <i v-if="isBindPhone" class="el-icon-success text-success font-size-26 m-r-20"></i>
          <i v-else class="el-icon-warning-outline text-warning font-size-26 m-r-20"></i>
          <span class="m-r-20">手机验证</span>
          <span v-if="isBindPhone" class="font-size-12 text-666">
            您验证的手机： {{ phoneEncipher }} 若已丢失或停用，请立即更换，避免账户被盗
          </span>
        </div>
        <el-button v-if="isBindPhone" type="text" @click="updatePhone">修改手机号</el-button>
        <el-button v-else type="text" @click="bindPhone">绑定手机号</el-button>
      </div>
      <div class="flex flex-row-center flex-between border-bottom-1 p-a-10">
        <div class="flex flex-row-center flex1">
          <i v-if="offiaccount.isBind" class="el-icon-success text-success font-size-26 m-r-20"></i>
          <i v-else class="el-icon-warning-outline text-warning font-size-26 m-r-20"></i>
          <span>{{ offiaccountName }}</span>
        </div>
        <el-button v-if="offiaccount.isBind" type="text" @click="unbind('offiaccount')">取消关注</el-button>
        <el-button v-else type="text" @click="bind('offiaccount')">关注</el-button>
      </div>

      <template v-if="false">
        <div class="flex flex-row-center flex-between border-bottom-1 p-a-10">
          <div class="flex flex-row-center flex1">
            <i v-if="isBindWeapp" class="el-icon-success text-success font-size-26 m-r-20"></i>
            <i v-else class="el-icon-warning-outline text-warning font-size-26 m-r-20"></i>
            <span>小程序微信绑定</span>
          </div>
          <el-button v-if="isBindWeapp" type="text" @click="unbind('weapp')">解绑</el-button>
          <el-button v-else type="text" @click="bind('weapp')">绑定</el-button>
        </div>
        <div class="flex flex-row-center flex-between border-bottom-1 p-a-10">
          <div class="flex flex-row-center flex1">
            <i v-if="isBindWeChatPC" class="el-icon-success text-success font-size-26 m-r-20"></i>
            <i v-else class="el-icon-warning-outline text-warning font-size-26 m-r-20"></i>
            <span>PC端快捷登录微信绑定</span>
          </div>
          <el-button v-if="isBindWeChatPC" type="text" @click="unbind('weChatPC')">解绑</el-button>
          <el-button v-else type="text" @click="bind('weChatPC')">绑定</el-button>
        </div>
      </template>
    </div>
    <!-- 修改密码 -->
    <ModifyPwdBox ref="ModifyPwdBox" />
    <!-- 绑定手机号 -->
    <BindPhoneBox ref="BindPhoneBox" />
    <!-- 修改手机号 -->
    <UpdatePhoneBox ref="UpdatePhoneBox" />
    <!-- 绑定微信公众号 -->
    <BindOffiaccountBox ref="BindOffiaccountBox" />

    <el-dialog
      :title="tipTitle"
      :visible.sync="tipVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      @close="closedFn"
      width="440px"
    >
      <div>
        <div class="m-t-20 flex flex-center">
          <Qrcode :link="qrcodeLink" :codeW="160" v-if="isWeChatPC" />
          <img v-else :src="tipImg" alt="" style="width:295px;" />
        </div>
        <div class="m-tb-20 text-center">
          {{ tipDesc }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import Qrcode from "@/components/Qrcode/index.vue";

import ModifyPwdBox from "./components/ModifyPwdBox.vue";
import BindPhoneBox from "./components/BindPhoneBox.vue";
import UpdatePhoneBox from "./components/UpdatePhoneBox.vue";
import BindOffiaccountBox from "./components/BindOffiaccountBox.vue";
import { unbindAccounts } from "@/api/accounts";

export default {
  name: "personalData",
  components: {
    SelectArea,
    Qrcode,
    ModifyPwdBox,
    BindPhoneBox,
    UpdatePhoneBox,
    BindOffiaccountBox
  },
  data() {
    return {
      accept: "image/jpeg,image/gif,image/png,image/bmp",
      defaultAvatar: require("@/assets/img/defaultAvatar.png"),
      avatarList: [],
      isEditUsername: false,

      userType: "personal", //个人用户“所在地区”可修改, 企业用户不可更改（数据取企业资料-所在地区）
      isEditUserarea: false,

      isBindWeapp: false, //是否绑定小程序
      isBindWeChatPC: false, //是否绑定微信
      tipTitle: "",
      tipVisible: false,
      tipDesc: "",
      isWeChatPC: false,
      qrcodeLink: "", // 二维码链接
      tipImg: ""
    };
  },
  computed: {
    ...mapState("user", {
      userInfo: state => {
        return state.userInfo;
      }
    }),
    offiaccount() {
      return this.userInfo.oplatformAccount.offiaccount;
    },
    offiaccountName() {
      if (!this.offiaccount.isBind) {
        return "关注公众号";
      } else {
        return `公众号【${this.offiaccount.appName}】`;
      }
    },
    isBindPhone() {
      return this.userInfo.phone && this.userInfo.phone !== "未绑定";
    },
    phoneEncipher() {
      return `${this.userInfo.phone.substring(0, 3)}****${this.userInfo.phone.substring(7)}`;
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    areaChange(dataObj) {
      console.log("dataObj", dataObj);
    },
    chooseImg() {
      this.$refs.imgUploadRef.click();
    },
    onFileChange(e) {
      console.log("===", e);
    },
    //刷新用户信息
    getUserInfo() {
      // 获取用户信息
      this.$store.dispatch("user/getUserInfo");
    },
    saveUsername() {
      this.isEditUsername = false;
      // this.save();
    },
    saveUserarea() {
      this.isEditUserarea = false;
      // this.save();
    },
    // 保存个人资料
    save() {
      // console.log("保存个人资料");
      this.getUserInfo();
    },
    editPwd() {
      this.$refs.ModifyPwdBox.open();
    },
    bindPhone() {
      this.$refs.BindPhoneBox.open();
    },
    updatePhone() {
      this.$refs.UpdatePhoneBox.open();
    },
    bind(type) {
      this.isWeChatPC = false;
      switch (type) {
        case "offiaccount":
          this.$refs.BindOffiaccountBox.open();
          break;
        case "weapp":
          this.tipTitle = "提示";
          this.tipImg = require("@/assets/img/mallStop.png");
          this.tipDesc = "请前往思锐云订货小程序进行微信授权绑定";
          this.tipVisible = true;
          break;
        case "weChatPC":
          this.tipTitle = "微信绑定";
          this.tipDesc = "请使用微信扫描二维码进行绑定";
          this.isWeChatPC = true;
          setTimeout(() => {
            const link = ["development"].includes(process.env.NODE_ENV)
              ? "https://3174n.qa.srgou.com/h5"
              : location.origin + "/h5";
            this.qrcodeLink = link;
          }, 400);
          this.tipVisible = true;
          break;
      }
    },
    closedFn() {
      // this.getUserInfo();
    },
    unbind(type) {
      let tipText = "";
      if (type == "offiaccount") {
        tipText = "是否确定取消关注？注意：取消后将不再收到公众号的推送消息,请谨慎操作.";
      } else {
        tipText = "是否确定解绑？";
      }
      this.$confirm(tipText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          switch (type) {
            case "offiaccount":
              this.unbindAccountsFn();
              break;
            case "weapp":
              console.log("解绑小程序", type);
              break;
            case "weChatPC":
              console.log("解绑微信", type);
              break;
          }
        })
        .catch(() => {});
    },
    // 解绑公众号
    unbindAccountsFn() {
      const params = {
        appid: this.offiaccount.appid
      };
      unbindAccounts(params)
        .then(() => {
          this.$message.success("取消关注成功");
          this.getUserInfo();
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.avatar-box {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  .img-upload {
    display: none;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    background: #82848a8a;
    border-radius: 50%;
  }
  &:hover {
    .img-upload {
      display: block;
    }
  }
}
.line-height-32 {
  line-height: 32px;
}
/deep/.el-button + .el-button {
  margin-left: 10px;
}
.qrcode-img {
  width: 160px;
  height: 160px;
  padding: 5px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>
