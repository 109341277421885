import request from "@/utils/request";

/**
 * 短信验证码
 */
export function getCaptchasSms(data) {
  return request({
    url: "/mall/captchas/sms",
    method: "post",
    data: data
  });
}

/**
 * 图片验证码
 */
export function getCaptchasImg(params) {
  return request({
    url: "/mall/captchas/image",
    method: "get",
    params
  });
}
